import { Offer } from "src/models/offer";
import Api from "..";
import { PaginatedState } from "src/models/paginatedState";
import { PaginatedResponse } from "src/models/paginatedResponse";

export class OffersService {
  static async getAll():Promise<Offer[]> {
    try {
      const { data } = await Api.client.get<Offer[]>(`offers`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<Offer>> {
    var params = {
      name,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = `offers/search`;
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<Offer>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(offer:Partial<Offer>, appIDs:string[], templateContent:any, stepsInternalCampaignIDs:any):Promise<Offer> {
    for (let prop in stepsInternalCampaignIDs) {
      if (stepsInternalCampaignIDs.hasOwnProperty(prop) && stepsInternalCampaignIDs[prop] <= 0) {
        delete stepsInternalCampaignIDs[prop];
      }
    }
    try {
      const { data } = await Api.client.post('offers', {
        ...offer,
        app_ids: appIDs,
        template_content: templateContent,
        steps_internal_campaign_ids: stepsInternalCampaignIDs,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(offer:Partial<Offer>, appIDs:string[], templateContent:any, stepsInternalCampaignIDs:any):Promise<Offer> {
    for (let prop in stepsInternalCampaignIDs) {
      if (stepsInternalCampaignIDs.hasOwnProperty(prop) && stepsInternalCampaignIDs[prop] <= 0) {
        delete stepsInternalCampaignIDs[prop];
      }
    }
    try {
      const { data } = await Api.client.post('offers/edit', {
        ...offer,
        app_ids: appIDs,
        template_content: templateContent,
        steps_internal_campaign_ids: stepsInternalCampaignIDs,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(offer:Partial<Offer>):Promise<void> {
    try {
      await Api.client.delete(`offers/${offer.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async refresh(offer:Partial<Offer>):Promise<Offer> {
    try {
      const { data } = await Api.client.get(`offers/${offer.id}/refresh`);
      return data.offer
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(offerID:number):Promise<Offer> {
    try {
      const { data } = await Api.client.post(`offers/${offerID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(offerID:number):Promise<Offer> {
    try {
      const { data } = await Api.client.post(`offers/${offerID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

}