
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Autocomplete, Box, Checkbox, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { PagesService } from 'src/api/services/PagesService';
import AddIcon from '@mui/icons-material/AddCircle';
import { Page } from 'src/models/page';
import { ApiException } from 'src/models/apiError';
import { Domain } from 'src/models/domain';
import { Template, TemplatePath, TemplateThumbnail } from 'src/models/template';
import { Product } from 'src/models/product';
import { RemoveCircle } from '@mui/icons-material';
import { Corporation } from 'src/models/corporation';
import { Term } from 'src/models/term';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ColorPicker from 'src/components/ColorPicker';
import { ProductsService } from 'src/api/services/ProductsService';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { PageProduct } from 'src/models/pageProduct';

interface PageDialogProps {
    isOpen: boolean
    existingPage?: Page
    onClose: (shouldRefresh: boolean) => void
    domains: Domain[]
    templates: Template[]
    corporations: Corporation[]
    terms: Term[]
}

const PageDialog: FC<PageDialogProps> = ({
    isOpen = false,
    existingPage,
    onClose,
    domains,
    templates,
    corporations,
    terms,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [path, setPath] = useState<string>('')
    const [domainID, setDomainID] = useState<string>('')
    const [domain, setDomain] = useState<Domain>()
    const [templateID, setTemplateID] = useState<string>('')
    const [template, setTemplate] = useState<Template>()
    const [templateContent, setTemplateContent] = useState<any>()
    const [externalCampaignID, setExternalCampaignID] = useState<string>('')
    const [corporationID, setCorporationID] = useState<string>('')
    const [corporation, setCorporation] = useState<Corporation>()
    const [termID, setTermID] = useState<string>('')
    const [term, setTerm] = useState<Term>()
    const [privacyID, setPrivacyID] = useState<string>('')
    const [privacy, setPrivacy] = useState<Term>()
    const [checkoutTermsID, setCheckoutTermsID] = useState<string>('')
    const [checkoutTerms, setCheckoutTerms] = useState<Term>()
    const [dba, setDba] = useState<string>('')
    const [supportPhone, setSupportPhone] = useState<string>('')
    const [supportEmail, setSupportEmail] = useState<string>('')
    const [supportReturnAddress, setSupportReturnAddress] = useState<string>('')
    const [supportHours, setSupportHours] = useState<string>('')
    const [descriptor, setDescriptor] = useState<string>('')
    const [products, setProducts] = useState<Product[]>([])
    const [isProductsAutocompleteOpen, setIsProductsAutocompleteOpen] = useState(false)
    const [isProductsAutocompleteLoading, setIsProductsAutocompleteLoading] = useState(false)
    const [pageProducts, setPageProducts] = useState<PageProduct[]>([])

    useEffect(() => {
        handeProductsFilter('')
    }, []);

    const handeProductsFilter = async value => {
        console.log(value);
        setIsProductsAutocompleteLoading(true)
        const p:PaginatedResponse<Product> = await ProductsService.search({name: value});
        setIsProductsAutocompleteLoading(false)
        setProducts(p.data);
    };
        

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingPage) {
            setName(existingPage.name)
            setPath(existingPage.path)
            setExternalCampaignID(existingPage.external_campaign_id)
            setDomainID(existingPage.domain_id)
            setCorporationID(existingPage.corporation_id)
            setTermID(existingPage.terms_id)
            setPrivacyID(existingPage.privacy_id)
            setCheckoutTermsID(existingPage.checkout_terms_id)
            setTemplateID(existingPage.template_id)
            if (existingPage.template_content) {
                setTemplateContent(existingPage.template_content)
            } else if (existingPage.template) {
                setTemplateContent(existingPage.template.content)
            }
            const domain = domains.find(d => `${d.id}` == existingPage.domain_id)
            setDomain(domain)
            const template = templates.find(t => `${t.id}` == existingPage.template_id)
            setTemplate(template)
            const corporation = corporations.find(d => `${d.id}` == existingPage.corporation_id)
            setCorporation(corporation)
            const term = terms.find(d => `${d.id}` == existingPage.terms_id)
            setTerm(term)
            const privacy = terms.find(d => `${d.id}` == existingPage.privacy_id)
            setPrivacy(privacy)
            const checkout_terms = terms.find(d => `${d.id}` == existingPage.checkout_terms_id)
            setCheckoutTerms(term)
            setDba(existingPage.dba)
            setSupportPhone(existingPage.support_phone)
            setSupportEmail(existingPage.support_email)
            setSupportHours(existingPage.support_hours)
            setSupportReturnAddress(existingPage.support_return_address)
            setDescriptor(existingPage.descriptor)
            const pp:PageProduct[] = []
            if (existingPage.products) {
                for (const p of existingPage.products) {
                    if(!p.pivot) continue
                    pp.push({
                        id: p.pivot.id,
                        page_id: p.pivot.page_id,
                        product_id: p.pivot.product_id,
                        external_offer_id: p.pivot.external_offer_id,
                        name: p.name,
                    })                    
                }
            }
            setPageProducts(pp)
        } else {
            setName('')
            setPath('')
            setExternalCampaignID('')
            setDomainID('')
            setCorporationID('')
            setTermID('')
            setPrivacyID('')
            setCheckoutTermsID('')
            setTemplateID('')
            setTemplateContent(undefined)
            setDomain(undefined)
            setTemplate(undefined)
            setCorporation(undefined)
            setTerm(undefined)
            setPrivacy(undefined)
            setDba('')
            setSupportPhone('')
            setSupportEmail('')
            setSupportHours('')
            setSupportReturnAddress('')
            setDescriptor('')
        }
        setError('')
    }, [existingPage])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try {
            await PagesService.delete({
                id: existingPage.id,
            })
            onClose(true)
        } catch (e) {
            if (e instanceof ApiException) {
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try {
            if (existingPage) {
                //Edit
                await PagesService.edit({
                    id: existingPage.id,
                    name,
                    path,
                    external_campaign_id: externalCampaignID,
                    domain_id: domainID,
                    corporation_id: corporationID,
                    terms_id: termID,
                    privacy_id: privacyID,
                    template_id: templateID,
                    checkout_terms_id: checkoutTermsID,
                    dba,
                    support_phone: supportPhone,
                    support_email: supportEmail,
                    support_hours: supportHours,
                    support_return_address: supportReturnAddress,
                    descriptor: descriptor,
                }, pageProducts, templateContent)
            } else {
                //Create
                await PagesService.create({
                    name,
                    path,
                    external_campaign_id: externalCampaignID,
                    domain_id: domainID,
                    corporation_id: corporationID,
                    terms_id: termID,
                    privacy_id: privacyID,
                    template_id: templateID,
                    checkout_terms_id: checkoutTermsID,
                    dba,
                    support_phone: supportPhone,
                    support_email: supportEmail,
                    support_hours: supportHours,
                    support_return_address: supportReturnAddress,
                    descriptor: descriptor,
                }, pageProducts, templateContent)
            }
            onClose(true)
        } catch (e) {
            if (e instanceof ApiException) {
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleDomainChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setDomainID(value)
        const domain = domains.find(d => `${d.id}` == value)
        setDomain(domain)
    }

    const handleCorporationChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setCorporationID(value)
        const corporation = corporations.find(d => `${d.id}` == value)
        setCorporation(corporation)
    }

    const handleTermChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setTermID(value)
        const term = terms.find(d => `${d.id}` == value)
        setTerm(term)
    }

    const handlePrivacyChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setPrivacyID(value)
        const privacy = terms.find(d => `${d.id}` == value)
        setPrivacy(privacy)
    }

    const handleCheckoutTermsChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setCheckoutTermsID(value)
        const term = terms.find(d => `${d.id}` == value)
        setCheckoutTerms(term)
    }

    const handleTemplateChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setTemplateID(value)
        const template = templates.find(t => `${t.id}` == value)
        setTemplate(template)
        if (template) {
            setTemplateContent({ ...template.content })
        }
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>, key: string, index?: number, child_key?: string) => {
        const { target } = event
        console.log(target)
        //const template = templates.find(t => `${t.id}` == value)
        const file = target.files[0];
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.onload = (e) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 800;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(async (blob) => {
                    const fileType = file.type.split("/")[1];
                    let newFile: File = null;

                    if (fileType === "jpeg" || fileType === "jpg") {
                        newFile = new File([blob], file.name, { type: "image/jpeg" });
                    } else if (fileType === "png") {
                        newFile = new File([blob], file.name, { type: "image/png" });
                    } else if (fileType === "gif") {
                        newFile = new File([blob], file.name, { type: "image/gif" });
                    } else {
                        return
                    }

                    let content = { ...templateContent }
                    if (index !== undefined && child_key !== undefined) {
                        //it's a child inside of an array
                        content[key][index][`${child_key}_file`] = await fileToBase64(newFile)
                    } else {
                        if (key.startsWith('variant')) {
                            const variantIndex = key.split('_')[1]
                            content['product_variants'][variantIndex]['file'] = await fileToBase64(newFile)
                        } else {
                            content[`${key}_file`] = await fileToBase64(newFile)
                        }
                    }
                    setTemplateContent(content)
                }, file.type, 0.8);
            };
            if (typeof e.target.result === 'string') {
                img.src = e.target.result;
            }
        }
        fileReader.readAsDataURL(file);
    }

    async function fileToBase64(file: File): Promise<string | ArrayBuffer> {
        const reader = new FileReader()
        return new Promise(resolve => {
            reader.onload = ev => {
                resolve(ev.target.result)
            }
            reader.readAsDataURL(file)
        })
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingPage ? 'Edit Page' : 'Create Page'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1, sm: 4 }} mb={2}>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            autoFocus
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={2}>
                        <FormControl fullWidth>
                            <InputLabel>Domain</InputLabel>
                            <Select
                                value={domainID}
                                label="Domain"
                                onChange={handleDomainChange}
                            >
                                {domains.map((domain) =>
                                    <MenuItem key={domain.id} value={domain.id}>{domain.host}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Sublytics Campaign ID"
                            value={externalCampaignID}
                            onChange={(e) => {
                                setExternalCampaignID(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel>Template</InputLabel>
                            <Select
                                value={templateID}
                                label="Template"
                                onChange={handleTemplateChange}
                                SelectDisplayProps={{
                                    style: {display: 'flex', alignItems: 'center'},
                                }}
                            >
                                {templates.map((template) =>
                                    <MenuItem key={template.id} value={template.id}>
                                        {template.image && <ListItemIcon sx={{minWidth: 56}}>
                                            <img height={32} src={`${TemplateThumbnail(template)}`} />
                                        </ListItemIcon>}
                                        <ListItemText primary={template.name} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                {template && <Grid container spacing={1} columns={{ xs: 2 }} mt={1} mb={2}>
                    {template.content.map((item) => {
                        const key = item.key
                        let templateContentValue = ''
                        if (templateContent) {
                            templateContentValue = templateContent[key] ?? ''
                        }
                        switch (item.type) {
                            case 'color': {
                                return <Grid item xs={1} key={key}>
                                    <ColorPicker defaultColor={templateContentValue} label={item.label}                                         onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e
                                            setTemplateContent(newContent)
                                        }} />
                                </Grid>
                            }
                            case 'image': {
                                return <Grid item xs={1} key={key}>
                                    <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                                        <Grid item xs={1}>
                                            <Button component="label" endIcon={<UploadFileIcon />}>
                                                {item.label}
                                                <input hidden accept="image/*" multiple type="file" onChange={(event) => handleImageChange(event, key)} />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                                                <Typography variant={'caption'}>{item.caption}</Typography>
                                                {templateContent[key] && templateContent[`${key}_file`] === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${templateContent[key]}`} sx={{
                                                    width: 'auto',
                                                    maxHeight: '50px'
                                                }} />}
                                                {templateContent[`${key}_file`] && <Box component="img" src={templateContent[`${key}_file`]} sx={{
                                                    width: 'auto',
                                                    maxHeight: '50px'
                                                }} />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            case 'variants': {
                                const contentVariants = templateContent[key] ?? []
                                return <Grid item xs={2} mb={1} key={key}>
                                    <Grid item xs={2}>
                                        <Typography mb={1} >
                                            Product Variants
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {contentVariants.map((variant, i) => {
                                            return <Grid container spacing={1} columns={{ xs: 3 }} key={`variant_${i}`}>
                                                <Grid item xs={1}>
                                                    <TextField
                                                        label={`Color`}
                                                        value={variant.color}
                                                        onChange={(e) => {
                                                            let newContent = { ...templateContent }
                                                            newContent[key][i].color = e.target.value
                                                            setTemplateContent(newContent)
                                                        }}
                                                        fullWidth
                                                    /></Grid>
                                                <Grid item xs={1}>
                                                    <TextField
                                                        label={`Hex`}
                                                        value={variant.hex}
                                                        onChange={(e) => {
                                                            let newContent = { ...templateContent }
                                                            newContent[key][i].hex = e.target.value
                                                            setTemplateContent(newContent)
                                                        }}
                                                        fullWidth
                                                    /></Grid>

                                                <Grid item xs={1}>
                                                    <Button component="label" endIcon={<UploadFileIcon />}>
                                                        <input hidden accept="image/*" multiple type="file" onChange={(event) => handleImageChange(event, `variant_${i}`)} />
                                                    </Button>
                                                    {variant.image && variant.file === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${variant.image}`} sx={{
                                                        width: 'auto',
                                                        maxHeight: '50px'
                                                    }} />}
                                                    {variant.file && <Box component="img" src={`${variant.file}`} sx={{
                                                        width: 'auto',
                                                        maxHeight: '50px'
                                                    }} />}
                                                </Grid>
                                            </Grid>

                                        })}

                                        <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                            let newContent = { ...templateContent }
                                            let variants = newContent[key]
                                            if (!variants) {
                                                newContent[key] = []
                                            }

                                            newContent[key].push({
                                                color: '',
                                                hex: '',
                                                image: '',
                                            })

                                            setTemplateContent(newContent)
                                        }}>
                                            Add Variant
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            case 'children': {
                                const contentChildren = templateContent[key] ?? []
                                return <Grid item xs={2} mb={1} key={key}>
                                    <Grid item xs={2}>
                                        <Typography mb={1} >
                                            {item.label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {contentChildren.map((childContent, i) => {
                                            return <Grid container spacing={1} columns={{ xs: 2 }} key={`children_${i}`}>
                                                {item.children != undefined && Object.keys(item.children).map((childKey, j) => {
                                                    const child = item.children[childKey]
                                                    switch (child.type) {
                                                        case 'text': {
                                                            return <Grid item xs={1} key={`child_${child.key}_${i}`}>
                                                                <TextField
                                                                    label={child.label}
                                                                    value={childContent[child.key]}
                                                                    placeholder={child.placeholder}
                                                                    onChange={(e) => {
                                                                        let newContent = { ...templateContent }
                                                                        newContent[key][i][child.key] = e.target.value
                                                                        setTemplateContent(newContent)
                                                                    }}
                                                                    fullWidth
                                                                /></Grid>
                                                        }
                                                        case 'image': {
                                                            return <Grid item xs={1} key={`child_${child.key}_${i}`}>
                                                                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                                                                    <Grid item xs={1}>
                                                                        <Button component="label" endIcon={<UploadFileIcon />}>
                                                                            {child.label}
                                                                            <input hidden accept="image/*" multiple type="file" onChange={(event) => handleImageChange(event, key, i, child.key)} />
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                                                                            <Typography variant={'caption'}>{child.caption}</Typography>
                                                                            {childContent[child.key] && childContent[`${child.key}_file`] === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${childContent[child.key]}`} sx={{
                                                                                width: 'auto',
                                                                                maxHeight: '50px'
                                                                            }} />}
                                                                            {childContent[`${child.key}_file`] && <Box component="img" src={childContent[`${child.key}_file`]} sx={{
                                                                                width: 'auto',
                                                                                maxHeight: '50px'
                                                                            }} />}
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    }
                                                    return <Grid item xs={1} key={`child_${j}`}>
                                                        Missing type</Grid>
                                                })}
                                                <Grid item xs={1}>
                                                    <Button component="label" endIcon={<RemoveCircle />} onClick={() => {
                                                        let newContent = { ...templateContent }
                                                        let deals = newContent[key]
                                                        deals.splice(i, 1)
                                                        setTemplateContent(newContent)
                                                    }}>
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        })}

                                        <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                            let newContent = { ...templateContent }
                                            let deals = newContent[key]
                                            if (!deals) {
                                                newContent[key] = []
                                            }

                                            const children = (item.children ?? []).map(c => {
                                                var obj = {}
                                                obj[c.key] = ''
                                                return obj
                                            })

                                            newContent[key].push(children.reduce((accumulator, current) => {
                                                return { ...accumulator, ...current };
                                            }, {}))

                                            setTemplateContent(newContent)
                                        }}>
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            case 'text': {
                                return <Grid item xs={1} key={key}>
                                    <TextField
                                        label={item.label}
                                        value={templateContentValue}
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e.target.value
                                            setTemplateContent(newContent)
                                        }}
                                        fullWidth
                                    /></Grid>
                            }
                            case 'textarea': {
                                return <Grid item xs={2} key={key}>
                                    <TextField
                                        multiline
                                        label={item.label}
                                        value={templateContentValue}
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e.target.value
                                            setTemplateContent(newContent)
                                        }}
                                        fullWidth
                                    /></Grid>
                            }
                            case 'richtext': {
                                return <Grid item xs={2} key={key}>
                                    <ReactQuill
                                        value={templateContentValue}
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e
                                            setTemplateContent(newContent)
                                        }}
                                    />
                                </Grid>
                            }
                            case 'checkbox': {
                                let templateContentValue = null
                                if (templateContent) {
                                    templateContentValue = templateContent[key] ?? null
                                }

                                return <Grid item xs={2} key={key}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox
                                            checked={templateContentValue != null}
                                            onChange={(e) => {
                                                let newContent = { ...templateContent }
                                                newContent[key] = e.target.checked ? '' : null
                                                setTemplateContent(newContent)
                                            }} />} label={item.label} />
                                    </FormGroup>
                                    {templateContentValue != null &&
                                        <TextField
                                            label={item.label}
                                            value={templateContentValue}
                                            placeholder={item.placeholder}
                                            onChange={(e) => {
                                                let newContent = { ...templateContent }
                                                newContent[key] = e.target.value
                                                setTemplateContent(newContent)
                                            }}
                                            fullWidth
                                        />}
                                </Grid>
                            }
                            case 'terms': {
                                return  <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel>{item.label}</InputLabel>
                                        <Select
                                            value={templateContentValue}
                                            label={item.label}
                                            onChange={(e) => {
                                                let newContent = { ...templateContent }
                                                newContent[key] = e.target.value
                                                setTemplateContent(newContent)
                                            }}
                                        >
                                            {terms.map((term) =>
                                                <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                        }
                    })}
                </Grid>}
                <Divider sx={{ mb: 1 }} />
                <Typography mb={1}>
                    Products
                </Typography>
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <FormControl sx={{
                            width: '100%',
                        }}>
                            <Autocomplete
                                id="asynchronous-demo"
                                fullWidth
                                open={isProductsAutocompleteOpen}
                                onOpen={() => {
                                    setIsProductsAutocompleteOpen(true);
                                }}
                                onClose={() => {
                                    setIsProductsAutocompleteOpen(false);
                                }}
                                isOptionEqualToValue={(option:any, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name}
                                options={products}
                                loading={isProductsAutocompleteLoading}
                                filterOptions={(x) => x}
                                onChange={(_, product, reason) => {
                                    console.log(reason)
                                    if(reason == 'selectOption'){
                                        const pp = [...pageProducts]
                                        pp.push({
                                            page_id: existingPage?.id,
                                            product_id: product.id,
                                            external_offer_id: '',
                                            name: product.name,
                                        })
                                        setPageProducts(pp)

                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Products"
                                    onChange={ev => {
                                        // dont fire API if the user delete or not entered anything
                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                          handeProductsFilter(ev.target.value);
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <>
                                            {isProductsAutocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                        ),
                                    }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {pageProducts.map((pageProduct, i) => {
                    return <Grid container spacing={1} columns={{ xs: 4 }} mb={1} key={`page_product_${i}`}>
                        <Grid item xs={1} justifyContent="center" display="flex" flexDirection="column">
                            <Typography>{pageProduct.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                label={'Sublytics Offer ID'}
                                value={pageProduct.external_offer_id}
                                onChange={(e) => {
                                    let pp = [...pageProducts]
                                    pp[i].external_offer_id = e.target.value
                                    setPageProducts(pp)
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button component="label" endIcon={<RemoveCircle />} onClick={() => {
                                let pp = [...pageProducts]
                                pp.splice(i, 1)
                                setPageProducts(pp)
                            }}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                })}
                <Divider sx={{ mb: 1 }} />
                <Typography mb={1}>
                    Page Information
                </Typography>
                <Grid container spacing={1} columns={{ xs: 1, sm: 3 }} mb={2}>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="DBA"
                            value={dba}
                            onChange={(e) => {
                                setDba(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Customer Service Phone"
                            value={supportPhone}
                            onChange={(e) => {
                                setSupportPhone(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Customer Service Email"
                            value={supportEmail}
                            onChange={(e) => {
                                setSupportEmail(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Customer Support Hours"
                            value={supportHours}
                            onChange={(e) => {
                                setSupportHours(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            label="Descriptor"
                            value={descriptor}
                            onChange={(e) => {
                                setDescriptor(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={3}>
                        <TextField
                            multiline
                            label="Return Address"
                            value={supportReturnAddress}
                            placeholder="Return Address"
                            onChange={(e) => {
                                setSupportReturnAddress(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Typography mb={1}>
                    Corporation
                </Typography>
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <FormControl fullWidth>
                            <InputLabel>Corporation</InputLabel>
                            <Select
                                value={corporationID}
                                label="Corporation"
                                onChange={handleCorporationChange}
                            >
                                {corporations.map((corporation) =>
                                    <MenuItem key={corporation.id} value={corporation.id}>{corporation.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography mb={1}>
                    Terms & Conditions
                </Typography>
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <FormControl fullWidth>
                            <InputLabel>Terms & Conditions</InputLabel>
                            <Select
                                value={termID}
                                label="Terms & Condition"
                                onChange={handleTermChange}
                            >
                                {terms.map((term) =>
                                    <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography mb={1}>
                    Privacy Policy
                </Typography>
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <FormControl fullWidth>
                            <InputLabel>Privacy Policy</InputLabel>
                            <Select
                                value={privacyID}
                                label="Privacy Policy"
                                onChange={handlePrivacyChange}
                            >
                                {terms.map((term) =>
                                    <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography mb={1}>
                    Checkout Terms
                </Typography>
                <Grid container spacing={1} columns={{ xs: 1 }} mt={1} mb={2}>
                    <Grid item xs={1}>
                        <FormControl fullWidth>
                            <InputLabel>Checkout Terms</InputLabel>
                            <Select
                                value={checkoutTermsID}
                                label="Checkout Terms"
                                onChange={handleCheckoutTermsChange}
                            >
                                {terms.map((term) =>
                                    <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            {error && <>
                <Alert severity="error">
                    {error}
                </Alert>
                <Divider />
            </>}
            <DialogActions>
                {existingPage && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton>}
                <Box sx={{ flex: '1 0 0' }} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingPage ? 'Save' : 'Create Page'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default PageDialog;

